import styles from '../css/Recent.module.css';
import Section from './Section';
import Masonry from 'react-masonry-css';
import WorkElements from '../assets/work/recent.json';
import { SRLWrapper } from 'simple-react-lightbox';

const Recent = () => {
    const breakpoints = {
        default: 3,
        1024: 2,
        768: 1
    };

    const lightboxOptions = {
        settings: {
            autoplaySpeed: 0,
            disableWheelControls: true   
        },
        buttons: {
            showAutoplayButton: false,
            showDownloadButton: false
        },
        caption: {
            captionFontFamily: 'Raleway, sans-serif'
        }
    };

    return (
        <Section id="recent" fit={true} content={true}>
            <div id={styles.wrapper}>
                <h1 className={styles.subheading}>Recent Work</h1>
                <div className={styles.content}>
                    <SRLWrapper options={lightboxOptions}>
                        <Masonry breakpointCols={1} className={styles.masonryGrid} columnClassName={styles.masonryGridColumn}>
                            {WorkElements.featured.map((element) => {
                                return (
                                    <div key={element.id}>
                                        <img src={element.data} alt={element.description} />
                                        <p>{element.name}</p>
                                    </div>
                                );
                            })}
                        </Masonry>
                        <Masonry breakpointCols={breakpoints} className={styles.masonryGrid} columnClassName={styles.masonryGridColumn}>
                            {WorkElements.common.map((element) => {
                                return (
                                    <div key={element.id}>
                                        <img src={element.data} alt={element.description} />
                                    </div>
                                );
                            })}
                        </Masonry>
                    </SRLWrapper>
                </div>
            </div>
        </Section>
    );
}

export default Recent;