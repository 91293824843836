import styles from '../css/Hero.module.css';
import Section from './Section';
import Konami from 'react-konami-code';

const Hero = () => {
    return (
        <Section id="hero" fit={true} content={true}>
            <section id={styles.hero}></section>
            <Konami code={[55, 50, 55]}>
                <div id={styles.easterEgg}>
                    <iframe width="1280" height="720" src="https://www.youtube.com/embed/UYNpkDrCWtA?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </Konami>
        </Section>
    );
}

export default Hero;