import styles from '../css/Footer.module.css';
import Section from './Section';

const Footer = () => {
    return (
        <Section fit={true} content={true}>
            <div id={styles.wrapper}>
                <p>Copyright &copy; {new Date().getFullYear()} Accalix. All rights reserved.</p>
                <p id={styles.twitter}>
                    <a href="https://twitter.com/accalix_" target="_blank" rel="noreferrer" aria-label="Twitter URL to Accalix Portfolio">
                        <i className="fab fa-twitter"></i>
                    </a>
                </p>
            </div>
        </Section>
    );
}

export default Footer;