import styles from '../css/Grid.module.css';

/**
 * alignItems?: flext-start, center, flex-end, stretch, baseline
 * justify?: flex-start, center, flex-end, space-between, space-around, space-evenly
 */

const Grid = (props) => {
    const {
        alignItems,
        children,
        column,
        expanded,
        justify,
        lg,
        md,
        row,
        sm,
        style
    } = props;

    const isRow = row || !column;

    const classes =
        (!isRow ? styles.column : styles.row) +
        (isRow && expanded ? ` ${styles.expanded}` : '') +
        (isRow && justify ? ` ${styles[justify]}` : '') +
        (isRow && alignItems ? ` ${styles['align-' + alignItems]}` : '') +
        (!isRow && sm ? ` ${styles['sm-' + sm]}` : '') +
        (!isRow && md ? ` ${styles['md-' + md]}` : '') +
        (!isRow && lg ? ` ${styles['lg-' + lg]}` : '');

    return <div style={style} className={classes}>{children}</div>;
};

export default Grid;