import Section from "./Section";
import styles from '../css/About.module.css';

const About = () => {
    return (
        <Section id="home" fit={true} content={true}>
            <section id={styles.wrapper}>
                <h1>Hey! I'm Sam.</h1>
                <div className={styles.content}>
                    <p>
                        An ambitious UK-based graphic designer with over 8 years of experience in driving brands' concepts into reality. My knowledge encompasses Adobe Suite, marketing, e-commerce, and digital media production.
                    </p>
                </div>
            </section>
        </Section>
    );
}

export default About;