import styles from '../css/Section.module.css';

const Section = ({ id, children, content, style, fit }) => {
    return (
        <section style={style} id={id} className={`${(content) ? 'content' : ''} ${(fit) ? styles.isMenu : ''} ${styles.section}`}>
            { children }
        </section>
    );
}

export default Section;