import React from 'react';
import ReactDOM from 'react-dom';
import './css/main.css';
import Portfolio from './Portfolio';
import SimpleReactLightbox from 'simple-react-lightbox';

ReactDOM.render(
    <React.StrictMode>
        <SimpleReactLightbox>
            <Portfolio />
        </SimpleReactLightbox>
    </React.StrictMode>,
    document.getElementById('root')
);