import styles from '../css/Contact.module.css';
import Section from './Section';

const Contact = () => {
    return (
        <Section id="home" fit={true} content={true}>
            <section id={styles.wrapper}>
                <h1>Contact</h1>
                <div className={styles.content}>
                    <p>
                        My commissions are currently: <b>Tentative</b>
                    </p>
                    <p>
                        If you'd like to reach out to me for any information, you can contact me directly through Discord or Twitter.
                    </p>
                    <div className={`${styles.contactItem} ${styles.discord}`}>
                        Accalix#1016
                    </div>
                    <div className={`${styles.contactItem} ${styles.twitter}`}>
                        @Accalix_
                    </div>
                    <div className={`${styles.contactItem} ${styles.email}`}>
                        sam@yuki.gg
                    </div>
                </div>
            </section>
        </Section>
    );
}

export default Contact;