import Section from "./Section";
import Grid from "./Grid";
import styles from '../css/Skills.module.css';

const Skills = () => {
    const photoshopExperience = new Date().getFullYear() - new Date(1451665656 * 1000).getFullYear();
    const aftereffectsExperience = new Date().getFullYear() - new Date(1546318152 * 1000).getFullYear();
    const illustratorExperience = new Date().getFullYear() - new Date(1577833200 * 1000).getFullYear();

    return (
        <Section id="skills" fit={true} content={true}>
            <div id={styles.wrapper}>
                <h1 className={styles.subheading}>Skills</h1>
                <div className={styles.content}>
                    <Grid row alignItems="flex-start" justify="center">
                        <Grid column sm={12} md={12} lg={4}>
                            <div className={`${styles.skillItem} ${styles.photoshop}`}>
                                Adobe Photoshop - {photoshopExperience} {(photoshopExperience > 1) ? 'Years' : 'Year'}
                            </div>
                            <div className={`${styles.skillItem} ${styles.aftereffects}`}>
                                Adobe After Effects - {aftereffectsExperience} {(aftereffectsExperience > 1) ? 'Years' : 'Year'}
                            </div>
                            <div className={`${styles.skillItem} ${styles.illustrator}`}>
                                Adobe Illustrator - {illustratorExperience} {(illustratorExperience > 1) ? 'Years' : 'Year'}
                            </div>
                        </Grid>
                        <Grid column sm={12} md={12} lg={1}>
                             &nbsp;
                        </Grid>
                        <Grid column sm={12} md={12} lg={7}>
                            <div id={styles.zebra}></div>
                        </Grid>
                    </Grid>
                    <div className={styles.divider}></div>
                </div>
            </div>
        </Section>
    );
}

export default Skills;