import styles from '../css/Navbar.module.css';
import logo from '../assets/images/logo-small.png';
import Section from './Section';
import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

const Navbar = () => {
    const [open, setOpen] = useState(false);

    return (
        <Section id="navbar" fit={true} content={true}>
            <header id={styles.header}>
                <nav className={styles.navbar}>
                    <span className={styles.navbarToggle} onClick={() => setOpen(!open)}>
                        <i className="fas fa-bars"></i>
                    </span>
                    <Link to="/">
                        <a href="/" className={styles.logo} aria-label="Accalix Home">
                            <img src={logo} alt="Accalix Logo" />
                        </a>
                    </Link>
                    <ul className={`${styles.mainNav} ${(open) ? styles.active : ''}`}>
                        <li>
                            <NavLink exact className={styles.navLinks} activeClassName={styles.navActive} to="/">
                                Home
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact className={styles.navLinks} activeClassName={styles.navActive} to="/contact" active>
                                Contact
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            </header>
        </Section>
    );
}

export default Navbar;