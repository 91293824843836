import Navbar from './components/Navbar';
import Hero from './components/Hero';
import About from './components/About';
import Skills from './components/Skills';
import Recent from './components/Recent';
import Contact from './components/Contact';
import Footer from './components/Footer';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

const Portfolio = () => {
    return (
        <Router>
            <Navbar />
            <Switch>
                <Route path="/contact">
                    <Hero />
                    <Contact />
                    <Footer />
                </Route>
                <Route path="/">
                    <Hero />
                    <About />
                    <Skills />
                    <Recent />
                    <Footer />
                </Route>
            </Switch>
        </Router>
    );
}

export default Portfolio;